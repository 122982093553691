import React, { useEffect, useState } from "react";
import { loginDetail, getApiCall, postApiCall } from "../Service/Api";
import Todo from "./Todo";
import Log from "./Log";
import FullScreenLoader from '../Component/FullScreenLoader';
import Footer from '../Component/Footer';
import AdminNotification from './AdminNotification';
import EmployeeNotification from './EmployeeNotification';
import Notification from './Notification';


function Dashboard({ setClockIn,setIsTrackingActive }) {

  const { format } = require("date-fns");
  const today = format(new Date(), "eeee, LLLL d");
  const userToken = loginDetail();
  const [latestTodo, setLatestTodo] = useState([]);
  const [latestAIMessage, setLatestAIMessage] = useState([]);
  const [userstatus, setUserstatus] = useState([]);
  const [actualWorkingSeconds, setActualWorkingSeconds] = useState();
  const [currentStatus, setCurrentStatus] = useState();
  const [loading, setLoading] = useState(true);

  const getDashboard = async () => {
 
    const response = await getApiCall("chat-manager/get-dashboard-data?rundev=rundev&limit=5");
    if(response.status) {
      setLatestAIMessage(response.data.latestAiChat);
      setActualWorkingSeconds(response.data.total_working_seconds);
      setCurrentStatus(response.data.currentStatus);
      setLatestTodo(response.data.todo_list);
      setUserstatus(response.data.userstatus);
      if(response.data.currentStatus=='off'){
        setClockIn(false);
      }else{
        setIsTrackingActive(true);
        setClockIn(true);
        getElectronCall();
      }
      setTimeout(() => {
        setLoading(false);
      }, 100);
    }

  };

  async function callClockIn() {
    setLoading(true);
    const response = await postApiCall(
      "clock-manager/update-work-status?rundev=rundev",
      { type: "start" }
    );
    if(response.status) {
      getDashboard();
      
    }

  }

  async function getElectronCall() {
    if (window.electron) {
      const { ipcRenderer } = window.electron;
      ipcRenderer.send('start-screenshots', {
        employee_id: userToken.user.employee_id,
        user_configuration: {
          is_image_taken: userToken.user.user_configuration.is_image_taken,
          image_time_taken: userToken.user.user_configuration.image_time_taken,
          is_time_taken: userToken.user.user_configuration.is_time_taken
        }
      });
      console.log('call once');

    } else {
      console.error('window not defined');
    }
  }

  useEffect(() => {
    getDashboard();
  }, []);

  return (
    <>
    {loading ? <FullScreenLoader /> :
      <div className="w-100">
        
        <div className="bg-white user-chat-topbar d-flex align-items-center justify-content-between border-0">
          <div>
            <h5 className="mb-0">Welcome {userToken.user.name}!</h5>
            <span className="d-flex align-items-center gap-2">
              <img src="../../assets/svg/date.svg" alt="date" /> {today}
            </span>
          </div>
        </div>
        <div className="w-100 p-3 bg-grey user-chat dashboardContentSection">
          
          {userToken.user.employee_type == "inhouse" && currentStatus=='off' ? (
            <div className="row mt-2" style={{ height: "85vh" }}>
              <div className="col-sm-12 d-flex justify-content-center align-items-center flex-column">
                <img src="../../assets/images/clock-in.png" alt="images"/>
                <button className="btn btn-primary" onClick={callClockIn}>
                  Clock In
                </button>
              </div>
            </div>
          ) : (
            <>
            <div className="row">
              <div className="col-sm-12 col-md-5 col-lg-5 mt-2">
                {userToken.user.employee_type == "inhouse" && (
                  <>
                    <Log actualWorkingSeconds={actualWorkingSeconds} setLoading={setLoading} currentStatus={currentStatus} setCurrentStatus={setCurrentStatus} setIsTrackingActive={setIsTrackingActive}/>
                    <EmployeeNotification />
                  </>
                )}
                
                <Notification latestAIMessage={latestAIMessage}/>
              </div>
              <div className="col-sm-12 col-md-7 col-lg-7 mt-2">
                <Todo latestTodo={latestTodo}/>
                {(userToken?.user?.is_admin==true) && (
                  <AdminNotification userstatus={userstatus}/>
                )}
              </div>
            </div>
            </>
          )}
        </div>
        <Footer />
      </div>
    }
    </>
    
  );
}

export default Dashboard;
