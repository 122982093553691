import React from "react";
import { Link } from "react-router-dom";

const Notification = ({ latestAIMessage }) => {

  return (
    <>
      <div className="dashboard-sections mt-4">
        <div className="d-flex align-items-center justify-content-between">
          <div className="header">
            <img src="../../assets/svg/time-dark.svg" alt="date" />
            <p className="m-0">Notification</p>
          </div>
          <Link to="/channel/m/system-mentor/Q7I=">View All</Link>
        </div>
        {latestAIMessage.map((item, index) => (
          <p className="channels-div" key={index}>
            {item.chat_formatted_time} - {item.message}
          </p>
        ))}
      </div>
    </>
  );
};

export default Notification;
