import React, { useEffect, useState, useRef,Suspense } from "react";
import { BrowserRouter as Router, Route, Routes} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pusher from "pusher-js";
import useToken from "./Auth/useToken";
import {loginDetail,getApiCall,postApiCall} from "./Service/Api";


// component
import Dashboard from "./Reporting/Dashboard";
import Login from "./Auth/Login";
import LeftSideBarMenu from "./Component/LeftSideBarMenu";
// routes
import {inhouseRoutes, adminRoutes, publicRoutes, authRoutes} from "./routesConfig";


function App() {

  
  const { token, setToken } = useToken();
  const userToken = loginDetail();
  const [clockIn, setClockIn] = useState(false); // State to track clock-in status
  const [isTrackingActive, setIsTrackingActive] = useState(false);
  const trackingIntervalRef = useRef(null);
  const [chatUpdate, setChatUpdate] = useState(0);
  const [personalChat, setPersonalChat] = useState([]);
  const [chatList, setChatList] = useState([]);
  const pusherInstance = useRef(null); // To keep track of the Pusher instance
  const audioPlayer = useRef(null);
  const notificationSoundUrl = process.env.PUBLIC_URL + "/assets/notification.mp3";
  let callTime = 1;
  
  const pathname = window.location.href;
  const splitLocation = pathname.split('/');
  
  useEffect(() => {
    if (callTime == 1) {
      initializePusher();
    }
    callTime = 1 + callTime;
  }, [token]);

  useEffect(() => {
    startActiveTracking();
  }, [isTrackingActive]); 

  async function startActiveTracking() {
    // Clear any existing interval
    clearInterval(trackingIntervalRef.current);

    if (isTrackingActive) {
      // Start new interval if tracking is not active
      trackingIntervalRef.current = setInterval(async () => {
        try {
          const response = await postApiCall("chat-manager/track-device-activity",{"action":"active","rundev":"rundev"});
          //console.log("tr:", response);
        } catch (error) {
          //console.error("Te:", error);
        }
      }, 600000); // Run every second
    }
  }
  
  async function initializePusher() {
    const userToken = loginDetail();

    if (userToken) {
      // Initialize Pusher
      pusherInstance.current = new Pusher("727315625cd81359da1a", {
        cluster: "ap2",
      });

      // Subscribing to personal chat channel
      let channel = pusherInstance.current.subscribe(
        "chat-pipe-" + userToken.user.employee_id
      );

      channel.bind("App\\Events\\ChatBroadCast", function (response) {
        setPersonalChat(response);
        if (response.type==1) {
          lastestChatUpdate();
          if (response.data.user.employee_id !== userToken?.user?.employee_id) {
            showTost("message");
          }
        }
      });

      // Fetch chat list from API
      const response = await getApiCall("chat-manager/getChatters?rundev=rundev");
      if (response.status) {
        setChatList(response.data);

        // Subscribe to favorite and regular group chats
        response.data?.favorite_groups?.forEach((group) => {
          channel = pusherInstance.current.subscribe("group-pipe-" + group.slug);
          channel.bind("App\\Events\\GroupBroadCast", handleGroupBroadcast);
          lastestChatUpdate();
        });

        response.data?.groups?.forEach((group) => {
          channel = pusherInstance.current.subscribe("group-pipe-" + group.slug);
          channel.bind("App\\Events\\GroupBroadCast", handleGroupBroadcast);
          lastestChatUpdate();
        });
      }
    }
  }

  function handleGroupBroadcast(response) {
    if(splitLocation['3']=='channel' && splitLocation['5']==response.data.group_slug){
      setPersonalChat(response);
    }
    if (response.type==1) {
      if(response.data.user.employee_id !== userToken.user.employee_id) {
        showTost("message");
      }
    }
  }

  function lastestChatUpdate() {
    setChatUpdate((prevChatUpdate) => prevChatUpdate + 1);
  }

  function showTost(msg) {
    const notificationElement = document.getElementById("notification");
    if (notificationElement) {
      notificationElement.muted = false;
      notificationElement.play().catch((error) => {
        console.error("Audio playback failed:", error);
      });
    }
  }

  return (
    <Router>
      <>
        <audio id="notification" ref={audioPlayer} src={notificationSoundUrl} />
        <ToastContainer theme="light" pauseOnHover position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss={false} draggable />
        {token ? (
            <div className="layout-wrapper bg-grey d-lg-flex">
              <LeftSideBarMenu />
              <Suspense>
                <Routes>
                  {userToken?.user?.employee_type === "inhouse" && (
                    <>
                      {clockIn ? (
                        <>
                          
                          {inhouseRoutes.map((route, index) => (
                            <Route key={index} path={route.path} element={React.cloneElement(route.element, { chatList,chatUpdate,lastestChatUpdate,setClockIn,setIsTrackingActive })}>
                              {route.children?.map((child, childIndex) => (
                                <Route
                                  key={childIndex}
                                  path={child.path}
                                  element={React.cloneElement(child.element, { setClockIn, personalChat,lastestChatUpdate })}
                                />
                              ))}
                            </Route>
                          ))}
                        </>
                      ) : (
                        <>
                          <Route
                            path="*"
                            element={<Dashboard setClockIn={setClockIn} setIsTrackingActive={setIsTrackingActive} />}
                          />
                        </>
                      )}
                    </>
                  )}

                  {userToken?.user?.is_admin && (
                    adminRoutes.map((route, index) => (
                      <Route
                        exact
                        key={index}
                        path={route.path}
                        element={route.element}
                      />
                    ))
                  )}
                  {userToken?.user?.employee_type != "inhouse" && (
                    <>
                    {publicRoutes.map((route, index) => (
                      <Route key={index} path={route.path} element={React.cloneElement(route.element, { chatList,chatUpdate,lastestChatUpdate,setClockIn,setIsTrackingActive })}>
                        {route.children?.map((child, childIndex) => (
                          <Route
                            key={childIndex}
                            path={child.path}
                            element={React.cloneElement(child.element, { setClockIn, personalChat,lastestChatUpdate })}
                          />
                        ))}
                      </Route>
                    ))}

                  </>
                )}
                <Route path="*" element={<Dashboard setClockIn={setClockIn} setIsTrackingActive={setIsTrackingActive} />} />
              </Routes>
              </Suspense>
           </div>
        ) : (
          <Suspense>
            <Routes>
                {authRoutes.map((route, index) => {
                  const { path, element } = route;
                  return (
                    <Route
                      exact
                      key={index}
                      path={path}
                      element={<route.element setToken={setToken} />}
                    />
                  );
                })}
                <Route path="*" element={<Login setToken={setToken} />} />
            </Routes>
          </Suspense>
        )}
        
      </>
    </Router>
  );
}

export default App;
