
import React, { useEffect, useState} from "react";
import {postApiCall, loginDetail} from "../Service/Api";
import { Modal, Button } from 'react-bootstrap';

function EmployeeNotification() {
  
  
  const week = new Date();
  week.setDate(week.getDate() - 1);
  let Week = week.toISOString().split("T")[0];

  const [userData, setUserData] = useState("");
  const [profile, setProfile] = useState(loginDetail());
  const [summation, setSummation] = useState("");
  const [usersSummary, setUsersSummary] = useState("");
  const [logDetail, setLogDetail] = useState("");
  const [modelShow, setModelShow] = useState(false);

  useEffect(() => {
    handleFilterData();
  }, []);

  const handleFilterData = async () => {
    let payload = {
      from_date: Week,
      to_date: Week,
      user_id: profile?.user?.id,
      rundev: "rundev",
      giveArray:"giveArray"
    };
    const response = await postApiCall("task-manager/get-daily-work-summary", payload);
    if (response.status) {
      setUserData(response.data.report);
      setSummation(response.data?.device_track);
      setUsersSummary(response.data.users_summary);
    }
  };

  const handleClose = () => setModelShow(false);
  const handleShow = (task) => {
    const processed = [];
    for (let i = 0; i < task.length - 1; i++) {
      const start = task[i];
      const end = task[i + 1];

      //if (start.type === "active" && end.type === "inactive") {
        const startTime = new Date(`01/01/2000 ${start.entry_time}`);
        const endTime = new Date(`01/01/2000 ${end.entry_time}`);
        const diffMs = endTime - startTime;
        const diffMinutes = Math.floor(diffMs / (1000 * 60));
        const diffHours = Math.floor(diffMinutes / 60);
        const remainingMinutes = diffMinutes % 60;

        processed.push({
          start_time: start.entry_time,
          end_time: end.entry_time,
          type: start.type,
          duration: `${diffHours}h ${remainingMinutes}m`,
        });
      //}
    }
    setLogDetail(processed);
    setModelShow(true);
  };

  return (
    <>
    {userData &&
        <>
        <div className="card mt-4">
            <div className="card-body dashboard-sections">
                <div className="row">
                    {userData &&
                        userData?.map((item, index) => (
                            item.summary?.map((sum, w) => (
                                <React.Fragment key={w}>
                                <div className="col-sm-12">
                                        <h5>Yesterday ({sum.date})
                                            <button className="btn"
                                                style={{float: "right"}}
                                                onClick={() => handleShow(sum.clock_tracks.anonymous_device)}
                                            >
                                                <i className="bx bx-show ms-2"></i>
                                            </button>
                                        </h5>
                                    </div>
                                <hr />
                                <div className="col-sm-4">
                                    <div className={(sum.was_late)?'channels-div text-center d-block bg-danger text-white':'channels-div text-center d-block'}>
                                        <h5>Clock IN</h5>
                                        <p>{sum.clock_tracks?.log[0].entry_time}</p>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="channels-div text-center d-block">
                                        <h5>Clock OUT</h5>
                                        <p>{sum.clock_tracks?.log[sum.clock_tracks?.log.length - 1]?.entry_time}</p>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="channels-div text-center d-block">
                                        <h5>In Time</h5>
                                        <p>{sum.clock_tracks.clock_activity?.office_time}</p>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="channels-div text-center d-block">
                                        <h5>Break Time</h5>
                                        <p>{sum.clock_tracks.clock_activity?.break_time}</p>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="channels-div text-center d-block">
                                        <h5>Working Hours</h5>
                                        <p>{sum.clock_tracks.clock_activity?.working_time}</p>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className={(sum.clock_tracks?.is_different)?'channels-div text-center d-block bg-danger text-white':'channels-div text-center d-block'} >
                                        <h5>Summary Time</h5>
                                        <p>{sum.clock_tracks.total_spent_hours}</p>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="channels-div text-center d-block">
                                        <h5>Active Time</h5>
                                        <p>{sum.clock_tracks.device_activity.inactive_time}</p>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="channels-div text-center d-block">
                                        <h5>InActive Time</h5>
                                        <p>{sum.clock_tracks.device_activity.active_time}</p>
                                    </div>
                                </div>
                                </React.Fragment>
                                
                            ))
                        ))
                    }
                    </div>
                </div>
            </div>
            <Modal show={modelShow} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>Device Log</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0">
                <table className="time-table table table-striped table-bordered">
                    <thead>
                    <tr>
                        <th>Start Time</th>
                        <th>End Time</th>
                        <th>Type</th>
                        <th>Duration</th>
                    </tr>
                    </thead>
                    <tbody>
                    {logDetail &&
                        logDetail?.map((entry, index) => (
                        <tr key={index}>
                            <td>{entry.start_time}</td>
                            <td>{entry.end_time}</td>
                            <td className={entry.type === "active" ? "" : "helightColor"}>
                                {entry.type === "active" ? "Working" : "Idle Time"}
                            </td>
                            <td>{entry.duration}</td>
                            </tr>
                        ))
                    }
                    
                </tbody>
                </table>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>
        </>
    }</>)
}
  
export default EmployeeNotification;


